import logo from './logo.svg';
import './App.css';
// import './assets/js/uikit.min.js'
// import './assets/js/uikit-icons.min.js'
// import './assets/js/fontawesome.all.min.js'
import $ from 'jquery';
import { useEffect, useState } from 'react';
import generalFlows from './dataLayer/generalFlows';
import Item_card from './Components/Item_card';
import Item_card2 from './Components/Item_card2';
import styled from 'styled-components'
import moment from 'moment';
import Footer from './Components/Footer';
import _ from 'lodash';

// const data = [
//   {
//     name: 'Creamy melt pizza',
//     desc: 'this is a pizza ',
//     price: 15,
//     type: "18 Mar",

//   },
//   {
//     name: 'Chicken pizza',
//     desc: 'this is a pizza ',
//     price: 25,
//     type: "15 Mar"
//   },
//   {
//     name: 'Veg lover  pizza',
//     desc: 'this is a pizza ',
//     price: 65,
//     type: "veglovers"
//   },
//   {
//     name: 'meat pizza',
//     desc: 'this is a pizza ',
//     price: 5,
//     type: "meat"
//   },
// ]

function App() {
  // const { chefid } = useParams();
  // const [searchParams, setSearchParams] = useSearchParams();
  const [selected, setselected] = useState();
  const [active, setactive] = useState('');
  const [dates, setdates] = useState([])
  const [data, setdata] = useState([])
  const [chefDetails, setchefDetails] = useState({})
  const [servingWindow, setservingWindow] = useState('NIGHT')



  useEffect(() => {
    var d = [];

    for (var i = 0; i < 7; i++) {
      var date = moment().add(i, 'days').format('DD MMM');
      d.push(date)
    }
    setactive(d[0])
    setdates(d)
  }, [])


  useEffect(async () => {

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    var chefid = params.chefid
    const handleItems = (d) => {
      var items = d.items
      setchefDetails(d.chefDetail)
      var arr = [];
      var priority = {
        MORNING: 1,
        AFTERNOON: 2,
        EVENING: 3,
        NIGHT: 4
      }
      for (var i = 0; i < items.length; i++) {
        var item = items[i]

        var obj = {
          name: item.name,
          desc: item.description,
          price: item.price,
          date: moment(item.date).format('DD MMM'),
          servingWindow: item.servingWindow,
          picUrl: item.imgUrl,
          available: item.available,
          originalDate: item.date,
          windowNumber: priority[item.servingWindow]
        }

        arr.push(obj)
      }
      setdata(arr)

      arr = _.sortBy(arr, ["originalDate", "windowNumber"]);

      console.log(arr, ' data ')
      var initalDate = arr[0].date;
      var initialWindow = arr[0].servingWindow



      setactive(initalDate)
      setservingWindow(initialWindow)

    }
    generalFlows.getItems(chefid, handleItems)
  }, [])
  useEffect(() => {
    $(window).on('load', function () {
      console.log('onload called')
      setTimeout(function () {
        $('.preloader').fadeOut().end().delay(500).fadeOut('slow');
      }, 1000);
    });
    $('.product-item__toggle button').on('click', function () {
      $(this).offsetParent('.product-item').find('.product-item__active').toggleClass('is-show');
      $(this).parent('.product-item__toggle').toggleClass('is-show');
    });
    $('.product-item__whish').on('click', function () {
      $(this).toggleClass('is-active');
    });
    $('.product-full-card__toggle button').on('click', function () {
      $(this).offsetParent('.product-full-card').find('.product-full-card__active').toggleClass('is-show');
      $(this).parent('.product-full-card__toggle').toggleClass('is-show');
    });
    $('.product-full-card__whish').on('click', function () {
      $(this).toggleClass('is-active');
    });
    $('.counter .minus').on('click', function () {
      var $input = $(this).parent().find('input');
      var count = parseInt($input.val()) - 1;
      count = count < 1 ? 1 : count;
      $input.val(count);
      $input.change();
      return false;
    });
    $('.counter .plus').on('click', function () {
      var $input = $(this).parent().find('input');
      $input.val(parseInt($input.val()) + 1);
      $input.change();
      return false;
    });
    $('.js-hidden-box').each(function () {
      $(this).children('div').hide();
      $(this).children('div').slice(0, 6).show();
      $(this).next('.js-hidden-btn').on('click', function (e) {
        e.preventDefault();
        $(this).prev('.js-hidden-box').children('div:hidden').slice(0, 3).slideDown();
        var elHidden = $(this).prev('.js-hidden-box').children('div:hidden').length;

        if (elHidden <= 0) {
          $(this).hide();
        }
      });
    });
    $('.js-checkbox').on('click', function () {
      $(this).toggleClass('is-checked');
    });


    var $preloader = $('#page-preloader'),
      $spinner = $preloader.find('.spinner-loader');
    $spinner.fadeOut();
    $preloader.delay(50).fadeOut('slow');


    const mediaQueryTablet = window.matchMedia('(max-width: 768px)');

    function handleTabletChange(e) {
      if (e.matches) {
        console.log('Media Query 768!');
      }
    }

    mediaQueryTablet.addListener(handleTabletChange);
    handleTabletChange(mediaQueryTablet);

  }, [])

  const Customfilterbutton = styled.button`
  background-color: #fff;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding-inline: 15px;
  margin-right: 20px;
  border:none;
  min-width: 60px;
  margin-top: 10px;
  font-weight: 600;
  font-size: 15px;
  transition: .5s;
    ${({ active }) => active && `
      border-color: #66db30;
      background-color:#66db30;
      color: #fff;
    `}  
  `
  //const selectedfilter = "All"
  // const ShowCard = (type) => {
  //     const newdata = data.filter((item)=>{
  //       return item.type === type;
  //     })

  //     setselected(newdata);
  //     selectedfilter = type
  //     setactive=selectedfilter
  // }



  return (<>
    <div >

      <div id="page-preloader"><span className="spinner border-t_second_b border-t_prim_a" /></div>
      {/* Loader end*/}
      <div className="page-wrapper">
        <header className="page-header">
          <div className="page-header__top">
            <div className="uk-container">
              <nav className="uk-navbar-container uk-navbar-transparent" data-uk-navbar>
                <div className="uk-navbar-left">
                  <div style={{ height: 30 }}></div>
                  <ul className="uk-navbar-nav">
                    <li><a href="index.html">Home</a></li>
                    {/* <li><a href="page-catalog-sidebar.html">Order Online</a></li>
                <li><a href="page-blog-article.html">Your Wishlist</a></li> */}
                  </ul>
                </div>
                <div className="uk-navbar-center">
                  <div className="logo">
                    <div className="logo__box"><a className="logo__link" href="index.html"> <img className="logo__img logo__w--full" src="assets/img/Group5433.png" alt="logo" /><img className="logo__img logo__img-small" src="assets/img/Group5433.png" alt="logo" /></a></div>
                  </div>
                </div>
                <div className="uk-navbar-right">{/*<a class="uk-button" href="page-pizza-builder.html"> <span>Make Your Pizza</span><img class="uk-margin-small-left" src="assets/img/icons/pizza.png" alt="pizza"></a> */}
                  <ul className="uk-navbar-nav">
                    {/* <li><a href="page-catalog.html">Our Menu</a></li>
                <li><a href="page-blog.html">Latest News</a></li>
                <li><a href="page-contacts.html">Contact us</a></li> */}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
          <div className="page-header__bottom">
            <div className="uk-container">
              <div className="uk-navbar-container uk-navbar-transparent" data-uk-navbar>
                {/* <div class="uk-navbar-left">
              <div>
                <div class="block-with-phone"><img src="assets/img/icons/delivery.svg" alt="delivery" data-uk-svg>
                  <div> <span>For Delivery, Call us</span><a href="tel:13205448749">1-320-544-8749</a></div>
                </div>
              </div>
            </div> */}
                <div className="uk-navbar-center" />
                {/* <div class="uk-navbar-right">
              <div class="other-links">
                <ul class="other-links-list">
                  <li><a href="#modal-full" data-uk-toggle><span data-uk-icon="search"></span></a></li>
                  <li><a href="#!"><span data-uk-icon="user"></span></a></li>
                  <li><a href="page-cart.html"><span data-uk-icon="cart"></span></a></li>
                </ul><a class="uk-button" href="page-pizza-builder.html"> <span>Make Your Pizza</span><img class="uk-margin-small-left" src="assets/img/icons/pizza.png" alt="pizza"></a>
              </div>
            </div> */}
              </div>
            </div>
          </div>
        </header>
        <main className="page-main">
          <div className="section-first-screen">
            <div className="first-screen__bg" style={{ backgroundColor: '#bc2b3b' }} />
            <div className="first-screen__content">
              <div className="uk-container">

                <div className="first-screen__box">
                  <div className="profile_picture">
                    <img style={{ height: 160, width: 160, borderRadius: 100, }} className="profile_image" src={chefDetails?.profilePic ? chefDetails?.profilePic.url : "./assets/img/Group5434.png"} />
                  </div>
                  <p className="first-screen__title">{chefDetails?.name}</p>
                  <p className="first-screen__desc">{chefDetails?.description}</p>
                  {/* <div class="first-screen__breadcrumb">
                <ul class="uk-breadcrumb">
                  <li><a href="/">Home</a></li>
                  <li><span>Pizza</span></li>
                </ul>
              </div> */}
                  <p className="download-app">Download the App</p>
                  <div className="appstorelink">
                    <img className="playstorelogo" src="./assets/img/Group32115.png" />
                    <img className="appstorelogo" src="./assets/img/Group32116.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="section-banners">
            <div className="uk-section uk-container">
              <div className="uk-grid uk-grid-medium uk-child-width-1-2@m" data-uk-grid>
                <div>
                  <div className="banner-card">
                    <div className="banner-card__bg" style={{ backgroundImage: 'url(assets/img/banners/1.jpg)' }} />
                    <div className="banner-card__box">
                      <h4 className="banner-card__title">Get Your Pizza In Your Style,<br /> With 4 Simple Steps!</h4><a className="banner-card__phone" href="tel:13205448749"><i className="fas fa-phone-square" /><span>Call us 1-320-544-8749</span></a>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="banner-card">
                    <div className="banner-card__bg" style={{ backgroundImage: 'url(assets/img/banners/2.jpg)' }} />
                    <div className="banner-card__box">
                      <h4 className="banner-card__title">Order 11” Pizzza &amp;<br /> Choice of One Appetizer!</h4><a className="banner-card__btn" href="#!">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="page-content">
            <div className="uk-container">
              <div data-uk-filter="target: .js-filter">
                <div className="catalog-filter-row">
                  {/* <ul className="uk-subnav"> */}
                  {/* onClick="Showallcard()" */}
                  {/* <li data-uk-filter-control="[data-tags='']"><a href="#">All</a></li>
                    <li className="uk-active" data-uk-filter-control="[data-tags='spicy']"><a href="#">Spicy</a></li>
                    <li data-uk-filter-control="[data-tags='chicken']"><a href="#">Chicken</a></li>
                    <li data-uk-filter-control="[data-tags='veglovers']"><a href="#">Veg lovers</a></li>
                    <li data-uk-filter-control="[data-tags='meat']"><a href="#">Meat</a></li> */}
                  {/* </ul> */}
                  <div className="filter-buttons">
                    {dates.map((item, index) => {
                      return <>
                        <Customfilterbutton active={active === item} onClick={() => setactive(item)} autofocus="autofocus">{item}</Customfilterbutton>

                      </>;
                    })}
                    {/* <Customfilterbutton active={active === 'All'} onClick={() => setactive('All')} autofocus="autofocus">All</Customfilterbutton>
                    <Customfilterbutton active={active === "spicy"} onClick={() => setactive("spicy")} >SPICY</Customfilterbutton>
                    <Customfilterbutton active={active === "chicken"} onClick={() => setactive("chicken")}>CHICKEN</Customfilterbutton>
                    <Customfilterbutton active={active === "veglovers"} onClick={() => setactive("veglovers")}>VEG LOVER</Customfilterbutton>
                    <Customfilterbutton active={active === "meat"} onClick={() => setactive("meat")}>MEAT</Customfilterbutton>
                    <Customfilterbutton active={active === "Dummy1"} onClick={() => setactive("Dummy1")}>Dummy1</Customfilterbutton>
                    <Customfilterbutton active={active === "dummy2"} onClick={() => setactive("dummy2")}>dummy2</Customfilterbutton> */}
                  </div>
                  <select className="uk-select" name="orderby" aria-label="Shop order"
                    onChange={(e) => { console.log(e.target.value); setservingWindow(e.target.value) }}
                    value={servingWindow}
                  >
                    {/* <option value="menu_order" selected>Sorting by</option> */}
                    <option value="MORNING">MORNING</option>
                    <option value="AFTERNOON">AFTERNOON</option>
                    <option value="EVENING">EVENING</option>
                    <option value="NIGHT">NIGHT</option>
                    {/* <option value="price-desc">Sort by price: high to low</option> */}
                  </select>
                </div>
                <ul className="js-filter uk-grid uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l" data-uk-grid>
                  {
                    active == 'All' ?
                      data.map((item, index) => {
                        return (
                          <Item_card2
                            type={item.type}
                            name={item.name}
                            description={item.desc}
                            price={item.price}
                            available={item.available}
                          />
                        )
                      }) :
                      data.map((item, index) => {
                        var count = 0
                        if (active == item.date && servingWindow == item.servingWindow) {
                          count++
                          return (
                            <Item_card2
                              type={item.type}
                              name={item.name}
                              description={item.desc}
                              price={item.price}
                              picUrl={item.picUrl}
                              available={item.available}

                            />
                          )
                        }

                      })
                  }

                </ul>
                {/* <div className="uk-margin-medium-top uk-text-center"><button className="uk-button" type="button">Load more </button></div> */}
              </div>
            </div>
          </div>
        </main>
        <footer className="page-footer">
          <div className="page-footer__top">
            <div className="uk-container">
              <div className="page-footer__logo">
                <div className="logo"> <a className="logo__link" href="index.html"><img className="logo__img" src="assets/img/Group5433.png" alt="logo" /></a></div>
              </div>

            </div>
          </div>

        </footer>

      </div>

    </div>
    <Footer /></>

  );
}

export default App;
