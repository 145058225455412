import React from 'react'
import Styles from '../styles/FooterStyle.module.css';
import AppStore from '../assets/images/Appstore.png';
import PlayStore from '../assets/images/Playstore.png';
// import Logo from '../assets/images/Logo.png';
import Twitter from '../assets/images/twitter.png';
import Facebook from '../assets/images/facebook.png';
function Footer() {
    return (
        <>
            <div className={Styles.footer}>
                <div className={Styles.footTop}>
                    <div className={Styles.firstsec}>
                        <div>
                            {/* <img src={Logo} /> */}
                        </div>
                        <div>
                            <a href="#"><h4>About</h4></a>
                            <a href="#"><h4>{"Terms & Conditions"}</h4></a>
                            <a href="#"><h4>Privacy Policy</h4></a>
                            <a href="/chef"><h4>Partner with us</h4></a>
                        </div>
                    </div>
                    <div className={Styles.social}>
                        <div>
                            <a href="#"><img src={Twitter}></img></a>
                            <a href="#"><img src={Facebook}></img></a>
                            <a href="#"><img src={Twitter}></img></a>
                        </div>
                        <div>
                            <a href="#"><img src={AppStore}></img></a>
                            <a href="#"><img src={PlayStore}></img></a>
                        </div>
                    </div>
                </div>
                <div className={Styles.bottext}>
                    <h5>By continuing past this page, you agree to our Terms of Service, Cookie Policy, Privacy Policy and Content Policies. All trademarks are properties of their respective owners. 2021 © Varalakshmi Sol. All rights reserved.</h5>
                </div>
            </div>
        </>
    );
}

export default Footer;