import React from 'react'

function Item_card2({ type, name, description, price, picUrl, available }) {
  return (
    // <li data-tags={type}>
    <div className="product-item">
      <div className="product-item__box">
        <div className="product-item__intro">
          <div className="product-item__not-active">
            <div className="product-item__media">
              <div className="uk-inline-clip uk-transition-toggle uk-light" data-uk-lightbox="data-uk-lightbox"><a href={picUrl}><img src={picUrl} alt="Item Image" />
                <div className="uk-transition-fade uk-position-cover uk-overlay uk-overlay-primary" />
                <div className="uk-position-center"><span className="uk-transition-fade" data-uk-icon="icon: search;" /></div>
              </a></div>
              {/* <div className="product-item__whish"><i className="fas fa-heart" /></div> */}
              {/* <div className="product-item__type spicy" />
                              <div className="product-item__tooltip" data-uk-tooltip="title: spicy pizza; pos: bottom-right"><i className="fas fa-info-circle" /></div> */}
            </div>
            <div className="product-item__title"><a href="page-product.html">{name}</a></div>
            <div className="product-item__desc">{description}</div>
            {/* <div className="product-item__select">
                              <div className="select-box select-box--thickness">
                                <ul>
                                  <li><label><input type="radio" name="thickness-1" defaultChecked="checked" /><span>Thin</span></label></li>
                                  <li><label><input type="radio" name="thickness-1" /><span>Normal</span></label></li>
                                  <li><label><input type="radio" name="thickness-1" /><span>Thick</span></label></li>
                                </ul>
                              </div>
                              <div className="select-box select-box--size">
                                <ul>
                                  <li><label><input type="radio" name="size-1" /><span>7 “</span></label></li>
                                  <li><label><input type="radio" name="size-1" /><span>9 “</span></label></li>
                                  <li><label><input type="radio" name="size-1" defaultChecked="checked" /><span>11 “</span></label></li>
                                </ul>
                              </div>
                            </div> */}
          </div>
          <div className="product-item__active">
            <div className="title">Add Ingredients</div>
            <div className="row">
              <div className="name">Meat Board</div>
              <div className="col"><span className="counter"><span className="minus">-</span><input type="text" defaultValue={1} /><span className="plus">+</span></span></div>
              <div className="price">20 s</div>
            </div>
            <div className="row">
              <div className="name">Pineapples</div>
              <div className="col"><span className="counter"><span className="minus">-</span><input type="text" defaultValue={1} /><span className="plus">+</span></span></div>
              <div className="price">35 s</div>
            </div>
            <div className="row">
              <div className="name">Sweet Pepper</div>
              <div className="col"><span className="counter"><span className="minus">-</span><input type="text" defaultValue={1} /><span className="plus">+</span></span></div>
              <div className="price">40 s</div>
            </div>
            <div className="row">
              <div className="name">Mushroom Mix</div>
              <div className="col"><span className="counter"><span className="minus">-</span><input type="text" defaultValue={1} /><span className="plus">+</span></span></div>
              <div className="price">20 s</div>
            </div>
            <div className="row">
              <div className="name">Sausage Salami</div>
              <div className="col"><span className="counter"><span className="minus">-</span><input type="text" defaultValue={1} /><span className="plus">+</span></span></div>
              <div className="price">35 s</div>
            </div>
            <div className="row">
              <div className="name">Fine Becon</div>
              <div className="col"><span className="counter"><span className="minus">-</span><input type="text" defaultValue={1} /><span className="plus">+</span></span></div>
              <div className="price">40 s</div>
            </div>
            <div className="row">
              <div className="name">Sauce "City Pizza"</div>
              <div className="col"><span className="counter"><span className="minus">-</span><input type="text" defaultValue={1} /><span className="plus">+</span></span></div>
              <div className="price">20 s</div>
            </div>
            <div className="row">
              <div className="name">Meat Board</div>
              <div className="col"><span className="counter"><span className="minus">-</span><input type="text" defaultValue={1} /><span className="plus">+</span></span></div>
              <div className="price">20 s</div>
            </div>
            <div className="row">
              <div className="name">Pineapples</div>
              <div className="col"><span className="counter"><span className="minus">-</span><input type="text" defaultValue={1} /><span className="plus">+</span></span></div>
              <div className="price">35 s</div>
            </div>
            <div className="row">
              <div className="name">Sweet Pepper</div>
              <div className="col"><span className="counter"><span className="minus">-</span><input type="text" defaultValue={1} /><span className="plus">+</span></span></div>
              <div className="price">40 s</div>
            </div>
            <div className="row">
              <div className="name">Mushroom Mix</div>
              <div className="col"><span className="counter"><span className="minus">-</span><input type="text" defaultValue={1} /><span className="plus">+</span></span></div>
              <div className="price">20 s</div>
            </div>
            <div className="row">
              <div className="name">Fine Susage</div>
              <div className="col"><span className="counter"><span className="minus">-</span><input type="text" defaultValue={1} /><span className="plus">+</span></span></div>
              <div className="price">55 s</div>
            </div>
          </div>
        </div>
        {/* <div className="product-item__toggle"> <button type="button"> <span>Additional Ingredients</span></button></div> */}
        <div className="product-item__info">
          <div className="product-item__price"> <span>Price: </span><span className="value">£{Number(price).toFixed(2)}</span></div>
          <div className="product-item__addcart"> <span style={{ fontFamily: "OpenSans" }} className='uk-button uk-button-default'>Available: {available}<span data-uk-icon="cart" /></span></div>
        </div>
      </div>
    </div>
    //   </li>
  )
}

export default Item_card2