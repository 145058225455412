
class Store {
    alert = null;
    setAlert(alert) {
        this.alert = alert;
        // alert.success('Welcome to ')
    }

    contactDetails = {
        name: '',
        email: '',
        phone: '',
        data: {
            message: ''
        }
    }

    neutrodevApiKey = "FB3Lc8N0Q7ti7Mu3N6tWVQ=="

    setContactDetails(key, value) {
        this.contactDetails[key] = value;
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validatePhone(phone) {
        var re = /^\d{10}$/;
        return re.test(String(phone).toLowerCase());
    }

    async submitContactDetails() {

        //validate if name, email and phone are not empty
        if (this.contactDetails.name && this.contactDetails.email && this.contactDetails.phone) {

            //validate if email is valid
            if (!this.validateEmail(this.contactDetails.email)) {
                this.alert.error('Please enter a valid email address');
                return;
            }

            //validate if phone is valid
            if (!this.validatePhone(this.contactDetails.phone)) {
                this.alert.error('Please enter a valid phone number');
                return;
            }




            var obj = this.contactDetails
            var raw = obj
            raw.validationType = this.logInType;
            console.log('login ', obj)

            var res = await fetch(
                `https://services.neutrodev.com/contactus`,
                {
                    method: "post",
                    headers: { "Content-Type": "application/json", "apikey": this.neutrodevApiKey },
                    // credentials: 'include',
                    body: JSON.stringify(raw)
                }
            );

            if (res.status == 200) {
                //empty the contact details
                this.contactDetails = {
                    name: '',
                    email: '',
                    phone: '',
                    data: {
                        message: ''
                    }
                }
                this.alert.success('contact details submitted')
                // window.location.reload();
                return true;
            } else {
                this.alert.error('Oops! something went wrong')
            }


        } else {
            this.alert.error('Please fill all the fields')
        }

    }

    async getItems(chefId, handleItems) {
        const res = await fetch(
            `https://api.chefsnearby.co.uk/ms/chefmenu?chefId=${chefId}`,
            {
                method: "get",
                headers: { "Content-Type": "application/json" },
            }
        )
        console.log(res)
        var data = await res.json();
        handleItems(data);
    }

}

const generalFlows = new Store();
export default generalFlows;